<template>
  <div>
    <div class="absolute">
      <!--
:class="{ [`invisible`]: toggle }"-->
      <span
        v-for="(item, index) in selectedSkills"
        :key="index"
        :ref="item + '-selected'"
        :data-skill-value="item"
        class="leading-tight inline-block transition duration-500 ease-in-out transform uppercase cursor-pointer bold border border-transparent rounded font-semibold px-1 py-1 text-xs whitespace-no-wrap mr-1"
        :class="{
          [`hover:bg-blue-100 hover:border-blue-700`]: true,
          [`hover:bg-blue-900 bg-blue-900 text-white`]: isItemSelected(item),
        }"
        @click="toggleSkill"
        v-text="item.replace(/-/g, ' ')"
      />
    </div>
    <div
      class="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-10"
    >
      <div
        v-for="(skill, parent_index) in skills"
        :key="parent_index"
        class="uppercase"
      >
        <span
          class="underline text-gray-600 font-semibold block px-1 pb-2 text-xs"
        >{{ skill.title }}</span>
        <ul>
          <li
            v-for="(item, index) in items(skill.title)"
            :key="index"
            class="py-1 leading-tight"
          >
            <span
              :ref="item"
              :data-skill-value="item"
              class="z-10 inline-block cursor-pointer bold border border-transparent rounded font-semibold px-1 py-1 text-xs whitespace-no-wrap"
              :class="{
                [`hover:bg-${skill.color}-100 hover:border-${skill.color}-700`]: true,
                [`hover:bg-${skill.color}-900 bg-${skill.color}-900 text-white`]: isItemSelected(
                  item
                ),
                [`hover:bg-${skill.color}-100 text-${skill.color}-700 `]: !isItemSelected(
                  item
                ),
              }"
              @click="toggleSkill"
              v-text="item.replace(/-/g, ' ')"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";
import { useMachine } from "@xstate/vue";
import { watchState } from "@/utils/machine-helpers";
import skillsMachine from "@/components//skills-machine";

export default {
  name: "Skills",
  props: {
    showAllSkills: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { state: skillsState, send: sendToSkillsMachine } = useMachine(
      skillsMachine
    );

    watchState(skillsState, "Skills");

    const skills = computed(() => skillsState.value?.context?.skills);
    const selectedSkills = computed(() => skillsState.value?.context?.values);

    return {
      skills,
      selectedSkills,
      skillsState,
      sendToSkillsMachine,
    };
  },
  methods: {
    items(title) {
      return this.$store.state.skills[title];
    },
    toggleSkill(event) {
      this.$store.commit("toggleSkill", event.target.dataset.skillValue);
    },
    isItemSelected(item) {
      return this.$store.state.values.includes(item);
    },
    onClick() {
      this.selectedSkills.forEach((element) => {
        const inter = this.$refs[element][0].getBoundingClientRect();
        const ref = this.$refs[element + "-selected"][0];
        const interTop = ref.getBoundingClientRect();
        ref.style.transform = `translate(${inter.x - interTop.x}px, ${
          inter.y - interTop.y
        }px)`;
      });

      //this.toggle = !this.toggle;
    },
  },
};
</script>
