import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "@aws-amplify/api";
//mport { getProfile } from "@/graphql/queries";
//import { createProfile } from "@/graphql/mutations";

const initialState = {
  skills: {
    languages: { color: "green", items: ["javascript", "c#", "java", "typescript", "sql"]},
    cloud: { color: "blue" , items: ["amazon-web-services", "azure", "google-cloud"]} ,
    frontend: { color: "pink" }, items: ["reactjs", "angular", "vuejs", "knockoutjs"]},
    bigdata: { color: "orange",  items: ["kafka", "spark", "snowflake", "neo4j"]},
    culture: {color: "green", items: [
      "friday-drinks",
      "table-tennis",
      "hackathons",
      "diversity",
      "retros",
      "innovation",
    ]},
    career: { color: "teal", items: [
      "graduate-programme",
      "internship",
      "training-days",
      "career-path",
    ]},
skills: [
    { title: "languages", color: "green" },
    { title: "cloud", color: "blue" },
    { title: "frontend", color: "pink" },
    { title: "bigdata", color: "orange" },
    { title: "culture", color: "green" },
    { title: "career", color: "teal" },
  ],
  values: {}
};

export default Machine(
  {
    id: "sk",
    initial: "idle",
    context: {
      ...initialState,
    },
    states: {
      idle: {
        on: {
          SHOW_SELECTED: "showSelected",
          SHOW_ALL: "showAll"
        }
      },
      showSelected: {},
      showAll: {}
    },
  }
);
